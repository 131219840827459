import { useContext } from "react";

import { useRoles } from "hooks";
import { Col, Dropdown, Nav, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import BreadCrumbs from "components/ui/BreadCrumbs";
import { breadcrumb, filesBreadcrumb } from "features/assessment/utils";
import { AuditContext } from "features/assessment/contexts/AuditProvider";

import AuditSettings from "./AuditSettings";
import AuditUpdate from "../../common/AuditUpdate";
import AuditDelete from "../../common/AuditDelete";

import "./SecondaryNavbar.scss";

const SecondaryNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname = "" } = location;
  const { audit } = useContext(AuditContext);
  const { isContributor, isCertifier } = useRoles();

  const isTabActive = audit?.protocol?.hasCorrectiveAction && !isCertifier;

  const handleAuditDeleted = () => navigate(`/facilities/${audit.facilityId}`);
  const handleAuditUpdated = () => navigate(0);

  const sideNavigationItems = [
    {
      cy: "summary",
      name: "Summary",
      icon: "insights",
      link: `/audits/${audit.auditId}`,
      show: true,
    },
    {
      cy: "files-photos",
      name: "File Library",
      icon: "drive_folder_upload",
      link: `/audits/${audit.auditId}/files`,
      show: audit?.protocol?.hasFiles,
    },
    {
      cy: "corrective-actions",
      name: "Corrective Actions",
      icon: "drive_folder_upload",
      link: `/audits/${audit.auditId}/corrective-actions`,
      show: isTabActive,
    },
    {
      cy: "assessment-team",
      name: "Assessment Team",
      icon: "group_add",
      link: `/audits/${audit.auditId}/members`,
      show: true,
    },
  ]
    .filter((item) => (isContributor ? item.cy === "files-photos" : true))
    .filter((item) => item.show);

  return (
    <Row className="position-fixed top-0 z-10 w-100 pt-5 mt-2 secondary-navbar">
      <Col className="d-flex">
        <BreadCrumbs
          className="my-0 d-flex align-items-center custom-breadcrumb text-truncate py-2"
          breadcrumbs={
            location.pathname === `/audits/${audit?.auditId}`
              ? breadcrumb(audit, location.pathname)
              : filesBreadcrumb(audit, location.pathname)
          }
        />
      </Col>
      <Col className="d-none d-xl-block">
        <Nav className="ms-auto align-items-center justify-content-end py-2">
          {sideNavigationItems.map((item) => {
            return (
              <Nav.Item key={item.cy}>
                <Nav.Link
                  as={Link}
                  to={item.link}
                  data-cy={item.cy}
                  active={pathname === item.link}
                  className="px-2 me-1 text-primary"
                >
                  {item.name}
                </Nav.Link>
              </Nav.Item>
            );
          })}
          {!isContributor && (
            <Dropdown className="mt-1">
              <Dropdown.Toggle variant="outline-primary" size="sm">
                <span
                  translate="no"
                  className="material-symbols-outlined md-16 me-2"
                >
                  settings
                </span>{" "}
                Settings{" "}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <AuditUpdate
                  audit={audit}
                  onAuditUpdated={handleAuditUpdated}
                />
                <AuditDelete
                  audit={audit}
                  onAuditDeleted={handleAuditDeleted}
                />
                <AuditSettings />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Nav>
      </Col>
      <Col className="d-sm-block d-xl-none" xs={12} sm={12} md={4}>
        <Nav className="ms-auto align-items-center float-end float-xs-start py-2 ">
          <Dropdown>
            <Dropdown.Toggle className="btn-sm bg-white bg-opacity-75 text-dark border-0 px-3 secondary-dropdown text-start py-1">
              {sideNavigationItems.find((item) => pathname === item.link)
                ?.name || "Section"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {sideNavigationItems.map((item) => {
                return (
                  <Dropdown.Item
                    key={item.cy}
                    as={Link}
                    to={item.link}
                    data-cy={item.cy}
                    active={pathname === item.link}
                  >
                    {item.name}
                  </Dropdown.Item>
                );
              })}
              <Dropdown.Divider />
              <Dropdown.Item disabled>
                <small>Settings</small>
              </Dropdown.Item>
              <AuditUpdate audit={audit} onAuditUpdated={handleAuditUpdated} />
              <AuditDelete audit={audit} onAuditDeleted={handleAuditDeleted} />
              <AuditSettings />
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Col>
    </Row>
  );
};

export default SecondaryNavbar;
