import { UserContext } from "contexts/UserProvider";
import React, { useContext } from "react";
import ErmIcon from "../ErmIcon";

const FilesAiInsightCard = ({ children, query, className = "" }) => {
  const user = useContext(UserContext);
  const { firstName, lastName } = user || {};
  return (
    <>
      {!!query && (
        <div className="d-flex align-items-start mb-3 justify-content-end">
          <div className="px-3 py-2 bg-light rounded fs-6">{query}</div>
          <div className="d-flex align-items-center justify-content-center rounded-circle bg-light text-dark text-uppercase ms-2 fs-6 p-2">
            <strong>{firstName[0] + lastName[0]}</strong>
          </div>
        </div>
      )}
      <div className={`d-flex align-items-start mb-3 ${className}`}>
        <div className="me-2 erm-logo-container">
          <ErmIcon fill={"#019A20"} />
        </div>
        {children}
      </div>
    </>
  );
};

export default FilesAiInsightCard;
