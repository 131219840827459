import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./ErrorHandler.scss";
import DOMPurify from "dompurify";

const allowedPaths = ["/login", "/reset-password", "/two-factor-code"];

const ErrorHandler = ({
  error,
  message,
  className = "",
  header = "Error!",
  children,
  defaultStatus = true,
}) => {
  const [status, setStatus] = useState(null);
  const [statusText, setStatusText] = useState(header);
  const [errorMsg, setErrorMsg] = useState(
    "Something unexpected happened. Please try again."
  );

  const maliciousUploadedMessage = (errorMsg || message).includes(
    "The uploaded file contains potentially harmful content "
  );

  const location = useLocation();
  const navigate = useNavigate();

  const handleRefresh = () => {
    window.location.reload();
  };

  const formatErrorMessage = (message) => {
    if (message.includes("These credentials did not match our records")) {
      return `These credentials did not match our records.<br/>
      Make sure you typed it correctly.<br/>
      Otherwise, contact ERM Support and Sustain.`;
    }
    return message;
  };

  useEffect(() => {
    if (error) {
      if (error.status && defaultStatus) {
        setStatus(error.status);
      }
      if (error.statusText) {
        setStatusText(error.statusText);
      }

      if (
        error.status === 401 &&
        allowedPaths.indexOf(location.pathname) === -1
      ) {
        navigate("/logout");
      }

      if (error.data) {
        if (typeof error.data.error === "object") {
          if ("message" in error.data.error) {
            let tmpErrorMessage =
              typeof error.data.error.message === "string"
                ? error.data.error.message
                : errorMsg;
            tmpErrorMessage = formatErrorMessage(tmpErrorMessage);
            setErrorMsg(tmpErrorMessage);
          } else if ("code" in error.data.error) {
            const tmpErrorMessage =
              typeof error.data.error.code === "string"
                ? error.data.error.code
                : errorMsg;
            setErrorMsg(tmpErrorMessage);
          } else {
            setErrorMsg(error.data.errors[Object.keys(error.data.errors)[0]]);
          }
        }

        if (typeof error.data.errors === "object") {
          if ("message" in error.data.errors) {
            setErrorMsg(error.data.errors.message);
          } else {
            setErrorMsg(error.data.errors[Object.keys(error.data.errors)[0]]);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, defaultStatus]);

  if (status === 403 && !maliciousUploadedMessage)
    return (
      <Alert variant="info" className={`my-3 d-flex flex-row ${className}`}>
        <div className="me-3">
          <span translate="no" className="material-symbols-outlined md-18">
            lock
          </span>
        </div>
        <div className="flex-fill">
          <h5 className="mb-1">
            <small>Unauthorized</small>
          </h5>
          <p>
            <small>Sorry, you do not have access rights!</small>
          </p>
          <p>
            <small>
              Try refreshing the page, if this doesn't work contact the admin
            </small>
          </p>
          {children || (
            <Button variant="outline-dark" size="sm" onClick={handleRefresh}>
              <span
                translate="no"
                className="material-symbols-outlined md-18 me-2"
              >
                refresh
              </span>
              {/* */}
              Refresh
            </Button>
          )}
        </div>
      </Alert>
    );

  if (status === 403 && maliciousUploadedMessage)
    return (
      <Alert variant="danger" className={`my-3 d-flex flex-row ${className}`}>
        <div className="me-3">
          <span translate="no" className="material-symbols-outlined md-18">
            report
          </span>
        </div>
        <div className="flex-fill">
          <h5 className="mb-1">
            <small>Potentially Harmful File Detected</small>
          </h5>
          <p>
            <small>{message || errorMsg}</small>
          </p>
        </div>
      </Alert>
    );

  return (
    <Alert variant="warning" className={`my-3 d-flex flex-row ${className}`}>
      <div className="me-3">
        <span translate="no" className="material-symbols-outlined md-18">
          warning
        </span>
      </div>
      <div>
        <h5 className="mb-1">
          <small>{statusText}</small>
        </h5>
        <p className="mb-1">
          <small
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(message || errorMsg),
            }}
          />
        </p>
      </div>
    </Alert>
  );
};

ErrorHandler.propTypes = {
  error: PropTypes.shape({
    statusText: PropTypes.string,
    status: PropTypes.number,
    data: PropTypes.objectOf(PropTypes.any),
  }),
  message: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorHandler;
