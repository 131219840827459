export const extractMlKeywordData = (metadata) => {
  return metadata
    .map((section) => {
      const sectionId = section.id;
      const sectionName = section.name;

      return section.questions
        .map((question) => {
          const questionId = question.id;
          const questionName = question.text;

          return question.observations.map((observation) => {
            const observationId = observation.id;
            const observationName = observation.text || "Observation 1";

            return {
              sectionId,
              sectionName,
              questionId,
              questionName,
              observationId,
              observationName,
            };
          });
        })
        .flat();
    })
    .flat();
};

export const groupMlKeywordData = (metadata) => {
  return extractMlKeywordData(metadata).reduce((sections, item) => {
    const {
      sectionId,
      sectionName,
      questionId,
      questionName,
      observationId,
      observationName,
    } = item;

    let section = sections.find((s) => s.sectionId === sectionId);
    if (!section) {
      section = { sectionId, sectionName, questions: [] };
      sections.push(section);
    }

    let question = section.questions.find((q) => q.questionId === questionId);
    if (!question) {
      question = { questionId, questionName, observations: [] };
      section.questions.push(question);
    }

    question.observations.push({ observationId, observationName });

    return sections;
  }, []);
};
