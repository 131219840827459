import React from "react";
import { Link } from "react-router-dom";

/**
 * 404: No match route
 */
const NoMatch = ({ location }) => (
  <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center bg-white">
    <h1 className="text-center">404 - Page not found</h1>
    <p className="text-center">
      You might want to go to the <Link to="/">/Home page</Link>
    </p>
  </div>
);

export default NoMatch;
