import { useCallback, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";

const useFetchFacilityAuditGroups = ({
  facilityId,
  page,
  perPage,
  sortState,
}) => {
  const subscribedPromises = useRef([]);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [meta, setMeta] = useState();

  const fetchGroups = useCallback(() => {
    setError(null);
    setIsLoading(true);

    const groupsPromise = get(`/facilities/${facilityId}/audit-groups`, {
      params: {
        page: page || 1,
        perPage: perPage || 10,
        sort: sortState === "asc" ? "-updated_at" : "updated_at",
      },
    });
    groupsPromise.promise
      .then(({ data: groups, meta }) => {
        setMeta(meta);
        if (Array.isArray(groups)) {
          setGroups(groups);
        } else {
          setGroups([]);
        }
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => setTimeout(() => setIsLoading(false), 500));
  }, [page, perPage, sortState, facilityId]);

  useEffect(() => {
    fetchGroups();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchGroups]);

  return { error, isLoading, groups, meta, setMeta, setGroups };
};

export default useFetchFacilityAuditGroups;
