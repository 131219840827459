import DOMPurify from "dompurify";
import { useToast } from "hooks";
import { useEffect, useRef, useState } from "react";
import { post } from "utils/DeApi";

const useCreateMemo = ({ auditId, onMemoCreate }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const createMemo = (values) => {
    setIsLoading(true);
    const { key, value } = values;
    const memoPromise = post(`/audits/${auditId}/memos`, {
      key: DOMPurify.sanitize(key),
      value: DOMPurify.sanitize(value),
    });

    memoPromise.promise
      .then(({ data }) => {
        if (typeof onMemoCreate === "function") onMemoCreate(data);
        toast.success("Success", "Notepad has been created successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(memoPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, createMemo };
};

export default useCreateMemo;
