import { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";

import { useToast } from "hooks";

import { put } from "utils/DeApi";

const useUpdateMemo = ({ memoId, onMemoUpdated }) => {
  const toast = useToast();
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const updateMemo = (values) => {
    setIsLoading(true);
    const { key, value } = values;
    const memoPromise = put(`/memos/${memoId}`, {
      key: DOMPurify.sanitize(key),
      value: DOMPurify.sanitize(value),
    });

    memoPromise.promise
      .then(({ data }) => {
        if (typeof onMemoUpdated === "function") onMemoUpdated(data);
        toast.success("Success", "Notepad has been updated successfully");
      })
      .catch((error) => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(memoPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { error, isLoading, updateMemo };
};

export default useUpdateMemo;
