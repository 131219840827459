import { useCallback, useEffect, useRef, useState } from "react";
import { post } from "utils/DeApi";

const useFetchPromptAnswers = ({ auditId }) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [promptAnswers, setPromptAnswers] = useState([]);

  const fetchPromptAnswers = useCallback(
    ({ prompt, threshold, regenerate }) => {
      setError(null);
      setIsLoading(true);

      const promptPromise = post(`ml/audits/${auditId}/files/query`, {
        query: prompt,
        threshold,
        ...(regenerate ? { regenerate } : {}),
      });

      promptPromise.promise
        .then(({ data }) => {
          setPromptAnswers((prev) => [
            ...prev,
            { ...data, query: prompt, id: Date.now() },
          ]);
        })
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(promptPromise);
    },
    [auditId]
  );

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchPromptAnswers]);

  return {
    isLoading,
    error,
    promptAnswers,
    setIsLoading,
    setError,
    setPromptAnswers,
    fetchPromptAnswers,
  };
};

export default useFetchPromptAnswers;
