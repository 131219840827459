import { useDownloadFileFromURL } from "features/entity/services";
import {
  removeExtraExtensions,
  removeDuplicateFiles,
} from "features/files/utils/formatFileNames";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";

const FileAiInsightDownload = ({ document }) => {
  const [fileId, setFileId] = useState(null);
  const { downloadFileFromURL, isLoading } = useDownloadFileFromURL({
    fileURL: "",
    fileName: "",
  });
  const files = !!document?.length
    ? document?.map(({ metadata: { file_name, file_url = "" } = {} }) => ({
        fileName: file_name,
        fileUrl: file_url,
      }))
    : [];
  const cleanedFiles = removeExtraExtensions(files);
  const uniqueFiles = removeDuplicateFiles(cleanedFiles);
  const description = uniqueFiles?.length === 1 ? "this file" : "these files";

  return (
    !!document?.length && (
      <div className="d-flex align-items-center flex-wrap mb-1">
        <div className="me-1">{`Summary is based on ${description}`} </div>
        {uniqueFiles.map(({ fileName, fileUrl = "" }, index) => {
          const lastItem = index === uniqueFiles.length - 1;
          const secondToLast = index === uniqueFiles.length - 2;
          const isDownloading = fileId === index && isLoading;

          return (
            <React.Fragment key={index}>
              {!fileUrl ? (
                <span className="text-primary ">{fileName}</span>
              ) : (
                <Button
                  size="sm"
                  variant="link"
                  className="p-0 m-0"
                  onClick={() => {
                    downloadFileFromURL(fileUrl, fileName);
                    setFileId(index);
                  }}
                  disabled={isDownloading}
                >
                  {fileName}
                  {isDownloading && (
                    <span translate="no">
                      <Spinner animation="border" size="sm" className="ms-1" />
                    </span>
                  )}
                </Button>
              )}
              <span className="white-space-pre">
                {secondToLast ? " and " : lastItem ? "." : ", "}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    )
  );
};

export default FileAiInsightDownload;
