import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Row, Col, Badge } from "react-bootstrap";
import ActionItemActionType from "../ActionItemActionType";
import { sanitizeActionItemObjectKeys } from "features/activityLogs/utils/sanitizeUtils";
import { truncate } from "lodash";
import { stripHTML } from "utils/StringUtils";
import { formatDatetimeInUTC } from "utils/DateUtils";

const actionItemStatusTypeLookup = {
  0: "Assigned",
  1: "Submitted",
  2: "Incomplete",
  3: "Accepted",
};
const actionItemTypesLookup = {
  0: "Administrative",
  1: "Corrective",
  2: "Certification",
};

const actionItemLogkeysLookuo = {
  action_item_assignee: ["subscriberIdName"],
  media: ["fileName"],
};

const ActionItemHistoryCard = ({
  causer: { firstName, lastName, email } = {},
  createdAt,
  type,
  subjectType,
  newProperties,
  oldProperties,
} = {}) => {
  const logs = Object.keys(
    Array.isArray(newProperties) ? oldProperties : newProperties || {}
  );

  const dueDate = (date) => {
    if (!date) return;
    return formatDatetimeInUTC(date);
  };

  const actionItemLogkeys = useCallback(() => {
    return actionItemLogkeysLookuo?.[subjectType] ?? logs;
  }, [logs, subjectType]);

  const actionItemStatusType = useCallback((statusNumber) => {
    return actionItemStatusTypeLookup?.[statusNumber] ?? "";
  }, []);

  const actionItemTypes = useCallback((typeNumber) => {
    return actionItemTypesLookup?.[typeNumber] ?? "";
  }, []);

  const [...keys] = actionItemLogkeys(subjectType);

  const label =
    type === "deleted" &&
    ["action_item_assignee", "comment"]?.includes(subjectType)
      ? "Removed"
      : "Previous";

  const getLogs = useCallback(
    (key) => {
      const previousVal = oldProperties?.[key] || "-";
      const currentVal = newProperties?.[key] || "-";

      if (key === "dueDate") {
        return {
          previousVal: previousVal ? dueDate(previousVal) : "-",
          currentVal: dueDate(currentVal) || "-",
        };
      }
      if (key === "actionItemType") {
        return {
          currentVal: actionItemTypes(currentVal) || "-",
          previousVal: actionItemTypes(previousVal) || "-",
        };
      }
      if (key === "done") {
        return {
          currentVal: actionItemStatusType(currentVal) || "-",
          previousVal: actionItemStatusType(previousVal) || "-",
        };
      }
      if (key === "description") {
        return {
          currentVal: stripHTML(currentVal) || "-",
          previousVal: stripHTML(previousVal) || "-",
        };
      }
      return {
        currentVal: currentVal || "-",
        previousVal: previousVal || "-",
      };
    },
    [actionItemStatusType, actionItemTypes, newProperties, oldProperties]
  );

  return (
    <div>
      <Row className="py-2">
        <Col className="border-0">
          <span className="fw-semibold">
            {firstName ?? "-"} {lastName ?? "-"}
          </span>
          <br />
          <small className="text-secondary fw-semibold">{email ?? "-"}</small>
        </Col>
      </Row>
      <Row className="py-2">
        <Col className="border-0 text-nowrap">
          <span className="text-secondary fw-semibold">Action:</span>
          <span className="ms-2 fw-semibold">
            <ActionItemActionType
              attributes={keys}
              type={type}
              subjectType={subjectType}
            />
          </span>
        </Col>
        <Col className={`border-0`} xs={6}>
          <span className="text-secondary fw-semibold ">Time Stamp:</span>
          <span className="ms-2 fw-semibold">
            {new Date(createdAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </span>
        </Col>
      </Row>
      {keys?.map((key, ind) => {
        const { currentVal, previousVal } = getLogs(key) || {};

        if (typeof currentVal === "object" && currentVal !== null) return <></>;
        if (typeof previousVal === "object" && previousVal !== null)
          return <></>;

        return (
          <Row className="py-2" key={ind}>
            <Col className="border-0">
              <span className="text-secondary fw-semibold">
                {label} {sanitizeActionItemObjectKeys(key)}:
              </span>

              {["description", "body"]?.includes(key) ? (
                <span className="ms-2" title={previousVal || "-"}>
                  {truncate(previousVal || "-", { length: 20 })}
                </span>
              ) : (
                <span className="ms-2 fw-semibold">
                  <Badge className="bg-info bg-opacity-10 text-info">
                    {previousVal || "-"}
                  </Badge>
                </span>
              )}
            </Col>
            {!(type === "deleted") && (
              <Col className="border-0">
                <span className="text-secondary fw-semibold">
                  Current {sanitizeActionItemObjectKeys(key)}:
                </span>
                {["description", "body"]?.includes(key) ? (
                  <span className="ms-2" title={currentVal || "-"}>
                    {truncate(currentVal || "-", { length: 20 })}
                  </span>
                ) : (
                  <span className="ms-2 fw-semibold">
                    <Badge className="bg-info bg-opacity-10 text-info">
                      {currentVal || "-"}
                    </Badge>
                  </span>
                )}
              </Col>
            )}
          </Row>
        );
      })}
    </div>
  );
};

ActionItemHistoryCard.propTypes = {
  causer: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
};

export default ActionItemHistoryCard;
