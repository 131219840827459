import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

export const sanitizeActionItemObjectKeys = (keyName) => {
  if (!keyName) return;
  if (keyName === "done") return "Status";
  if (keyName === "actionItemType") return "Type";
  if (keyName === "item") return "Title";
  if (keyName === "dueDate") return "Due Date";
  if (keyName === "subscriberIdName") return "Assignee";
  if (keyName === "body") return "Comment";
  if (keyName === "fileName") return "File";

  return capitalizeFirstLetter(keyName);
};

export const sanitizeAssessmentObjectKeys = (keyName) => {
  if (keyName === "name") {
    return "Title";
  }
  if (keyName === "dueDate") {
    return "Due Date";
  }
  if (keyName === "executiveSummary") {
    return "Executive Summary";
  }
  if (keyName === "protocolName") {
    return "Protocol";
  }
  if (keyName === "auditGroupName") {
    return "Audit Group";
  }
  if (keyName === "startedAt") {
    return "Started At";
  }
  if (keyName === "completedAt") {
    return "Completed At";
  }
  return capitalizeFirstLetter(keyName);
};

export const sanitizeEntityObjectKeys = (keyName) => {
  if (keyName === "name") {
    return "Title";
  }
  if (keyName === "facilitySuppliesTo") {
    return "Supplies To";
  }
  if (keyName === "facilitySuppliesFrom") {
    return "Supplies From";
  }
  if (keyName === "lepc") {
    return "LEPC (Local Emergency Planning Committee)";
  }
  if (keyName === "facility24HrPhoneNumber") {
    return "Entity 24hrs Phone Number";
  }
  if (keyName === "naicsCode") {
    return "NAICS code";
  }
  if (keyName === "facilityId") {
    return "Facility ID";
  }
  if (keyName === "facilityType") {
    return "Entity Type";
  }
  if (keyName === "facilityPhoneNumber") {
    return "Entity Phone Number";
  }
  if (keyName === "maxOccupancy") {
    return "Max Occupancy";
  }
  if (keyName === "fireDepartment") {
    return "Fire Department";
  }
  if (keyName === "mailingAddress") {
    return "Mailing Address";
  }
  if (keyName === "numberOfEmployees") {
    return "Number Of Employees";
  }
  if (keyName === "dunAndBradstreetNumber") {
    return "Dun & Bradstreet Number";
  }
  return capitalizeFirstLetter(keyName);
};

export const renderLogLabels = (_tmpLabel) => {
  if (_tmpLabel === "action_item_assignee") return "Action Item Assignee";
  return _tmpLabel;
};

export const sanitizeObservationKeys = (keyName) => {
  if (keyName === "assignedTo") {
    return "Assigned To";
  }
  if (keyName === "auditedAt") {
    return "Audited At";
  }
  if (keyName === "fullName") {
    return "Full Name";
  }
  return capitalizeFirstLetter(keyName);
};
