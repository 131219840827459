const LOADING_MESSAGES = [
  "Initializing ML engine",
  "Extracting Assessment data",
  "Processing assessment data",
  "Sending data to ML Engine",
  "Initializing the summary generation",
  "Applying the desired formatting",
  "Adding final touchups",
];

export default LOADING_MESSAGES;
