import { Link } from "react-router-dom";
import { Offcanvas, Button } from "react-bootstrap";
import { sanitizeHTML, truncateText } from "utils/StringUtils";
import { groupMlKeywordData } from "features/assessment/utils/extractMLKeywords";

import "./MLKeywordSource.scss";

const MLKeywordSource = ({ show, onHide, keyword, auditId }) => {
  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={onHide}
      className="w-fixed-640"
    >
      <Offcanvas.Header className="border-bottom" closeButton>
        <Offcanvas.Title>
          Sentiment Keywords Source:{" "}
          <span className="fw-normal text-capitalize">{keyword.keyword}</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {groupMlKeywordData(keyword.metadata).map((section, sectionIndex) => (
          <div key={sectionIndex} className="mt-2 border-bottom">
            <h4 class="fw-bold section-color">{section.sectionName}</h4>
            {section.questions.map((question, questionIndex) => (
              <div key={questionIndex} className="ms-3">
                <div className="d-flex">
                  <p>
                    {`${questionIndex + 1}.`}{" "}
                    <span className="me-3 text-muted">Question:</span>
                  </p>
                  <span>
                    <Link
                      to={`/audits/${auditId}/sections/${section.sectionId}/questions/${question.questionId}`}
                    >
                      <span
                        className="text-wrap text-capitalize"
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHTML(
                            truncateText(question.questionName, 140)
                          ),
                        }}
                      />
                    </Link>
                  </span>
                </div>

                <ul>
                  {question.observations.map((obs, obsIndex) => (
                    <li key={obsIndex}>{obs.observationName}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
        <div className="d-flex flex-row p-3 bg-light position-absolute bottom-0 w-100">
          <div className="flex-fill"></div>

          <Button
            size="sm"
            className="me-2"
            onClick={onHide}
            variant="outline-secondary"
          >
            Cancel
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MLKeywordSource;
