import { useContext } from "react";

import { groupBy, sortBy, uniqBy } from "lodash";

import { AuditContext } from "../contexts/AuditProvider";
import { sortArrayByDate } from "utils/ArrayUtils";

const getQuestionsWithObservations = (questions, observations) => {
  const groupedObservations = groupBy(observations, "questionId");
  return questions.map((question) => ({
    ...question,
    observations: sortArrayByDate(
      groupedObservations[question.questionId] || []
    ).reverse(),
  }));
};

const getObservationsWithResponses = (observations, responses) => {
  return observations.map((observation) => {
    return {
      ...observation,
      responses: responses.filter(
        ({ observationId }) => observation.observationId === observationId
      ),
    };
  });
};

const useAuditMapping = () => {
  const { setAudit } = useContext(AuditContext);

  const observationCreateOrUpdateMapping = (nextObservation) => {
    setAudit((prevState) => {
      const { observations = [], responses = [] } = prevState || {};

      const found = observations.find(({ observationId }) => {
        return nextObservation?.observationId === observationId;
      });

      const updatedObservations = found
        ? observations.map((prevObservation) => {
            if (
              nextObservation?.observationId === prevObservation.observationId
            )
              return {
                ...prevObservation,
                ...nextObservation,
                suggestedAnswers: prevObservation.suggestedAnswers
                  ? null
                  : nextObservation.suggestedAnswers,
                mlResponses: prevObservation.suggestedAnswers
                  ? prevObservation.suggestedAnswers
                  : prevObservation.mlResponses,
              };
            return prevObservation;
          })
        : [
            ...observations,
            { ...nextObservation, attachments: [], responses: [], geoTags: [] },
          ];

      const observationsWithResponses = getObservationsWithResponses(
        updatedObservations,
        responses
      );

      return {
        ...prevState,
        observations: observationsWithResponses,
        questions: getQuestionsWithObservations(
          prevState?.questions,
          observationsWithResponses
        ),
      };
    });
  };

  const observationDeleteMapping = (observation) => {
    setAudit((prevAudit) => {
      const observations = prevAudit.observations.filter(
        (obs) => obs.observationId !== observation.observationId
      );
      const responses = prevAudit.responses.filter(
        (res) => !observation.responses.map(({ id }) => id).includes(res.id)
      );

      return {
        ...prevAudit,
        responses: responses,
        observations: observations,
        questions: getQuestionsWithObservations(
          prevAudit?.questions,
          observations
        ),
      };
    });
  };

  const responseUpdateMapping = (nextResponses, nextObservation) => {
    setAudit((prevState) => {
      const { responses = [], observations = [] } = prevState || {};
      const prevResponses = responses.filter(({ observationId }) => {
        return nextObservation?.observationId !== observationId;
      });

      const found = observations.find(({ observationId }) => {
        return nextObservation?.observationId === observationId;
      });

      const updatedObservations = found
        ? observations.map((prevObservation) => {
            if (
              nextObservation?.observationId === prevObservation.observationId
            )
              return {
                ...prevObservation,
                ...nextObservation,
              };
            return prevObservation;
          })
        : [
            ...observations,
            { ...nextObservation, attachments: [], responses: [], geoTags: [] },
          ];

      const updatedResponses = [...prevResponses, ...nextResponses];

      const observationsWithResponses = getObservationsWithResponses(
        updatedObservations,
        updatedResponses
      );

      const questions = getQuestionsWithObservations(
        prevState?.questions,
        observationsWithResponses
      );

      return {
        ...prevState,
        responses: updatedResponses,
        observations: observationsWithResponses,
        questions: questions,
      };
    });
  };

  const observationsInapplicabilityMapping = (nextObservations = []) => {
    setAudit((prevState) => {
      const { protocol, observations = [], questions = [] } = prevState || {};
      let prevObservations = [...observations];

      nextObservations.forEach((updated) => {
        const { observationId: id } = updated || {};
        const found = observations.find(
          ({ observationId }) => observationId === id
        );

        if (found) {
          prevObservations = prevObservations.map((observation) => {
            const { observationId } = observation || {};
            if (observationId === found?.observationId) {
              return {
                ...observation,
                isInapplicable: updated.isInapplicable,
              };
            }
            return observation;
          });
        } else {
          prevObservations.push({
            ...updated,
            responses: [],
            attachments: [],
            commentsCount: 0,
          });
        }
      });

      //remove duplicates
      const observationUnique = !protocol?.hasObservationSets
        ? uniqBy(sortBy(prevObservations, "createdAt").reverse(), "questionId")
        : prevObservations;

      const groupedObservations = groupBy(observationUnique, "questionId");

      return {
        ...prevState,
        observations: observationUnique,
        questions: [...questions].map((q) => ({
          ...q,
          observations: groupedObservations[q.questionId] || [],
        })),
      };
    });
  };

  return {
    responseUpdateMapping,
    observationDeleteMapping,
    observationCreateOrUpdateMapping,
    observationsInapplicabilityMapping,
  };
};

export default useAuditMapping;
