import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OrganizationContext } from "contexts/OrganizationProvider";
import PortfolioCreate from "../Portfolios/PortfolioCreate";
import PortfoliosNav from "../Portfolios/PortfoliosNav";

const LeftSidebar = ({ error, isLoading, portfolios, setPortfolios }) => {
  const navigate = useNavigate();
  const organization = useContext(OrganizationContext);

  const [isAscOrder, setIsAscOrder] = useState(true);

  return (
    <>
      <h1>{organization?.name}</h1>
      <p>
        <small>
          Last updated{" "}
          {new Date(organization?.updatedAt).toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          })}
        </small>
      </p>

      <h5 className="mb-3">
        <Link
          data-cy="files-photos"
          className="text-decoration-none"
          to="/files"
        >
          File Library{" "}
          <span
            translate="no"
            className="float-end material-symbols-outlined md-20"
          >
            drive_folder_upload
          </span>
        </Link>
      </h5>
      <hr />

      <div className="d-flex flex-row  mb-3">
        <div className="flex-fill">
          <h3
            className="pt-1 pointer"
            onClick={() => setIsAscOrder((prev) => !prev)}
          >
            Portfolios{" "}
            <span
              translate="no"
              className="material-symbols-outlined md-18 text-muted"
            >
              {isAscOrder && "arrow_upward"}
              {!isAscOrder && "arrow_downward"}
            </span>
          </h3>
        </div>
        <div>
          <PortfolioCreate
            onPortfolioCreated={(p) => {
              setPortfolios((prevState) => [p, ...prevState]);
              navigate(`/portfolios/${p.id}`);
            }}
          />
        </div>
      </div>
      <PortfoliosNav
        error={error}
        isAscOrder={isAscOrder}
        isLoading={isLoading}
        portfolios={portfolios}
      />
    </>
  );
};

export default LeftSidebar;
