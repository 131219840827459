import * as yup from "yup";

const memoValidationSchema = yup.object().shape({
  key: yup
    .string()
    .required("This field is required")
    .min(3, "Title is too short!")
    .max(65000, "Title is too long!"),
  value: yup
    .string()
    .required("This field is required")
    .min(3, "Text is too short!")
    .max(65000, "Text is too long!"),
});

export default memoValidationSchema;
