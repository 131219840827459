import { useCopyToClipboard, useToast } from "hooks";
import { Fragment, useEffect } from "react";
import Markdown from "react-markdown";
import FilesAiInsightCard from "../FilesAiInsightCard";
import FileAiInsightDownload from "./FileAiInsightDownload";

const FilesAiInsightsContentList = ({
  threshold,
  promptAnswers,
  fetchPromptAnswers,
  setQuery,
  onQuerySearch,
}) => {
  const toast = useToast();
  const { handleCopy } = useCopyToClipboard();
  const noData = [
    "Looks like we couldn’t find anything. Let’s try adjusting your search!",
  ];

  const handleScrollToBottom = () => {
    const offcanvasBody = document.querySelector(".offcanvas-body");
    if (!offcanvasBody) return;
    offcanvasBody.scrollTop = offcanvasBody.scrollHeight;
  };

  useEffect(() => {
    if (promptAnswers?.length) handleScrollToBottom();
  }, [promptAnswers?.length]);

  return (
    !!promptAnswers?.length &&
    promptAnswers?.map(
      ({ query, summary, document, id, suggestion = "" }, ind) => {
        const suggestionExists = noData.includes(summary) && !!suggestion;
        const suggestedQuery = suggestion?.replace(/^['"]|['"]$/g, "");

        return (
          <Fragment key={id}>
            <FilesAiInsightCard query={query}>
              <div className="px-3 py-2 rounded fs-6 bg-insights text-break text-dark w-100">
                {!suggestionExists && (
                  <>
                    <FileAiInsightDownload document={document} />
                    <Markdown>{summary}</Markdown>
                  </>
                )}
                {!!suggestionExists && (
                  <>
                    {summary}
                    <p className="mb-1 ">
                      <strong className="me-1">Did you mean this?</strong>
                      <span
                        className="text-decoration-underline text-primary cursor-pointer"
                        onClick={() => onQuerySearch(suggestedQuery)}
                      >
                        {suggestedQuery}
                      </span>
                    </p>
                  </>
                )}
                <div className="d-flex align-items-center justify-content-end mt-2">
                  <span
                    className="material-symbols-outlined md-18 me-2 cursor-pointer"
                    onClick={() => {
                      handleCopy(summary).then(() =>
                        toast.success(
                          "Success",
                          "Your content has been copied to the clipboard"
                        )
                      );
                    }}
                  >
                    content_copy
                  </span>
                  <span
                    className="material-symbols-outlined md-18 cursor-pointer"
                    onClick={() => {
                      setQuery((prev) => ({
                        ...prev,
                        loadingPrompt: query,
                      }));
                      fetchPromptAnswers({
                        prompt: query,
                        threshold,
                        regenerate: true,
                      });
                      setTimeout(() => handleScrollToBottom(), 100);
                    }}
                  >
                    restart_alt
                  </span>
                </div>
              </div>
            </FilesAiInsightCard>
          </Fragment>
        );
      }
    )
  );
};

export default FilesAiInsightsContentList;
