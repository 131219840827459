import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import ErrorHandler from "components/ui/ErrorHandler";
import { addYears } from "features/actionItems/utils";
import { useFetchSubscribers } from "features/organization";
import { useBulkUpdateActionItems } from "features/actionItems/services";
import { actionItemBulkUpdateValidationSchema } from "features/actionItems/schemas";
import ActionItemBulkInfoModal from "./ActionItemBulkInfoModal";
import RequiredAsterisk from "components/ui/RequiredAsterisk";

const ActionItemBulkAssignModal = ({
  selectedItems,
  modalBtnVariant,
  onActionItemsUpdated,
  isBulkAssignDisabled,
  facilityId,
}) => {
  const [show, setShow] = useState(false);
  const [warning, setWarning] = useState(true);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setWarning(true);
    setShow(false);
    setError(null);
  };
  const handleShow = () => setShow(true);

  const {
    subscribers,
    isLoading: subscribersLoading,
    error: subscribersError,
  } = useFetchSubscribers({
    path: show ? `facilities/${facilityId}/subscribers` : "",
  });

  const {
    bulkUpdateActionItems,
    isLoading: bulkUpdateActionItemsLoading,
    error: bulkUpdateActionItemsError,
  } = useBulkUpdateActionItems({
    selectedItems,
    onActionItemsUpdated: (data) => {
      onActionItemsUpdated(data);
      handleClose();
    },
  });

  const isLoading = subscribersLoading || bulkUpdateActionItemsLoading;
  const [{ dueDate, assignedTo = [] } = {}] = selectedItems;

  const assigneeIds = new Set(
    assignedTo.map(({ subscriberId }) => subscriberId)
  );

  const isNotSameDate = selectedItems.some((item) => item.dueDate !== dueDate);
  const isNotSameAssignee = selectedItems.some((item) => {
    const itemAssigneeCount = item?.assignedTo?.length;

    if (itemAssigneeCount !== assigneeIds.size) return true;

    return item.assignedTo.some(
      ({ subscriberId }) => !assigneeIds.has(subscriberId)
    );
  });

  const isDataNotSame = isNotSameDate || isNotSameAssignee;
  const isWarning = show && warning && isDataNotSame;

  useEffect(() => {
    setError(subscribersError || bulkUpdateActionItemsError);
  }, [bulkUpdateActionItemsError, subscribersError]);

  return (
    <>
      <Button
        variant={modalBtnVariant}
        size="sm"
        onClick={handleShow}
        disabled={isBulkAssignDisabled}
      >
        Bulk Update
      </Button>
      {isWarning ? (
        <ActionItemBulkInfoModal
          show={show}
          handleClose={handleClose}
          setWarning={setWarning}
        />
      ) : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Bulk Update {selectedItems.length} Selected Action Item
              {selectedItems.length > 1 ? "s" : ""}
            </Modal.Title>
          </Modal.Header>
          <Formik
            validationSchema={actionItemBulkUpdateValidationSchema}
            onSubmit={(values) => bulkUpdateActionItems(values)}
            initialValues={{
              assignedTo: assignedTo || [],
              dueDate: "",
            }}
          >
            {({
              handleSubmit,
              handleBlur,
              values,
              setFieldValue,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Group controlId="dueDate" className="mb-3">
                    <Form.Label className="mb-1">Due Date</Form.Label>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      type="date"
                      name="dueDate"
                      selected={values.dueDate}
                      popperPlacement="top-start"
                      className="action-item-datepicker form-control"
                      onChange={(date) => {
                        setFieldValue("dueDate", date || "");
                      }}
                      maxDate={addYears(new Date(), 2)}
                      onBlur={handleBlur}
                      isValid={values.dueDate && !errors.dueDate}
                      isInvalid={
                        !(values.dueDate && !errors.dueDate) && touched.dueDate
                      }
                      isClearable
                      placeholderText="Enter Due Date"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dueDate && touched.dueDate ? (
                        <small>{errors.dueDate}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="assignedTo" className="mb-3">
                    <Form.Label className="mb-1">
                      Assigned To <RequiredAsterisk />
                    </Form.Label>
                    <Typeahead
                      id="assigned-to-typeahead"
                      clearButton
                      placeholder="Assigned to"
                      isLoading={false}
                      labelKey={(option) =>
                        `${option.firstName} ${option.lastName}`
                      }
                      isValid={
                        !!values.assignedTo.length &&
                        !errors.assignedTo &&
                        touched.assignedTo
                      }
                      isInvalid={
                        !(values.assignedTo.length && !errors.assignedTo) &&
                        touched.assignedTo
                      }
                      onChange={(assignedTo) => {
                        setFieldValue("assignedTo", assignedTo);
                      }}
                      options={subscribers.filter(
                        (s) =>
                          !assignedTo
                            .map(({ subscriberId }) => subscriberId)
                            .includes(s.subscriberId)
                      )}
                      selected={values.assignedTo}
                      multiple
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.assignedTo && touched.assignedTo ? (
                        <small>{errors.assignedTo}</small>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {error ? <ErrorHandler error={error} /> : null}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isLoading}
                    size="sm"
                  >
                    <Spinner animation="border" size="sm" hidden={!isLoading} />{" "}
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default ActionItemBulkAssignModal;
