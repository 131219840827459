import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import "./FilesAiInsights.scss";
import { useFetchPromptAnswers } from "features/files/services";
import ErrorHandler from "components/ui/ErrorHandler";
import InsightsIcon from "./InsightsIcon";
import FilesAiInsightCard from "./FilesAiInsightCard";
import FilesAiInsightsContentList from "./FilesAiInsightsContentList";
import { UserContext } from "contexts/UserProvider";
import { OrganizationContext } from "contexts/OrganizationProvider";

const FilesAiInsights = ({ auditId }) => {
  const organization = useContext(OrganizationContext);
  const user = useContext(UserContext);
  const sliderRef = useRef(null);
  const [show, setShow] = useState(false);
  const { firstName } = user || {};

  const [query, setQuery] = useState({});
  const [threshold, setThreshold] = useState(0);
  const [indicatorPosition, setIndicatorPosition] = useState(0);

  const { prompt, loadingPrompt } = query || {};

  const {
    isLoading,
    error,
    fetchPromptAnswers,
    promptAnswers,
    setPromptAnswers,
    setError,
  } = useFetchPromptAnswers({
    auditId,
  });

  const handleShow = () => setShow(true);

  const onReset = () => {
    setPromptAnswers([]);
    setQuery({});
    setError(null);
    setThreshold(0);
    setIndicatorPosition(0);
  };
  const handleClose = () => {
    setShow(false);
    setTimeout(() => onReset(), 100);
  };

  const handleScrollToBottom = () => {
    const offcanvasBody = document.querySelector(".offcanvas-body");
    if (!offcanvasBody) return;
    offcanvasBody.scrollTo({
      top: offcanvasBody.scrollHeight,
      behavior: "smooth",
    });
  };

  const onQuerySearch = (prompt) => {
    if (!prompt) return;
    fetchPromptAnswers({ prompt, threshold });
    setQuery((prev) => ({ ...prev, loadingPrompt: prompt, prompt: "" }));
    setTimeout(() => handleScrollToBottom(), 100);
  };

  useEffect(() => {
    if (show) setThreshold(1);
  }, [show]);

  useEffect(() => {
    const updateIndicatorPosition = () => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderRect = slider.getBoundingClientRect();
        const thumbPosition = ((threshold - 0) / (2.0 - 0)) * sliderRect.width;

        setIndicatorPosition(thumbPosition);
      }
    };

    updateIndicatorPosition();
    window.addEventListener("resize", updateIndicatorPosition);

    return () => {
      window.removeEventListener("resize", updateIndicatorPosition);
    };
  }, [threshold]);

  if (!organization?.mlFeaturesEnabled) return <></>;

  return (
    <>
      <Button
        className="me-2 d-flex align-items-center py-1 insights-button"
        title="AI Insights"
        size="sm"
        variant="outline-primary"
        onClick={handleShow}
      >
        <InsightsIcon className="m-1 me-2" fill={""} />
        AI Insights
      </Button>

      <Offcanvas show={show} placement="end" className="w-fixed-640">
        <Offcanvas.Header
          onHide={handleClose}
          className="border-bottom"
          closeButton
        >
          <Offcanvas.Title>AI Insights</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="h-calc-120">
          <FilesAiInsightCard className="align-items-start">
            <div className="px-3 py-2 rounded fs-6 bg-insights text-break text-dark w-100">
              <strong>
                Hi {firstName}! Welcome to ERM Assess AI Insights.
              </strong>
              <br />
              How can we help you today?
              <br />
              Ask your question and get insights from the provided files.
            </div>
          </FilesAiInsightCard>
          <FilesAiInsightsContentList
            promptAnswers={promptAnswers}
            fetchPromptAnswers={fetchPromptAnswers}
            setQuery={setQuery}
            threshold={threshold}
            onQuerySearch={onQuerySearch}
          />
          {isLoading && (
            <FilesAiInsightCard
              query={loadingPrompt}
              className="align-items-center"
            >
              <div className="px-3 py-2 rounded fs-6">
                <span className="insights-loader text-primary" />
              </div>
            </FilesAiInsightCard>
          )}
          {show && error && <ErrorHandler error={error} />}
        </Offcanvas.Body>
        <div className="sticky-bottom p-3 bg-light">
          <Form.Group className="mt-3 d-flex align-items-start gap-2 w-100 position-relative">
            <Form.Label className="text-dark fw-semibold mb-0">
              Similarity Threshold:
            </Form.Label>
            <span className="fw-semibold">0</span>
            <div className="position-relative w-25">
              <Form.Range
                ref={sliderRef}
                max={2.0}
                min={0}
                step={0.1}
                value={threshold}
                onChange={(e) => setThreshold(parseFloat(e.target.value))}
                className="h-auto"
              />
              <div
                className="slider-indicator px-2 py-1 rounded"
                style={{ left: `${indicatorPosition}px` }}
              >
                {threshold.toFixed(1)}
              </div>
            </div>
            <span className="fw-semibold">2.0</span>
          </Form.Group>
          <p className="mb-2 mt-0 fw-normal">
            <small>
              <span translate="no" className="material-symbols-outlined md-18">
                info
              </span>{" "}
              The similarity threshold checks relevance, and shows how much your
              text matches other sources.
            </small>
          </p>
          <div className="input-group mb-3">
            <Form.Control
              type="text"
              id="prompt"
              aria-describedby="prompt"
              placeholder="Enter your query"
              value={prompt}
              onChange={(e) => {
                setQuery((prev) => ({
                  ...prev,
                  prompt: e.target.value,
                }));
              }}
              onKeyDown={(e) => e.key === "Enter" && onQuerySearch(prompt)}
            />
            <Button
              variant="outline-primary"
              className="form-control search-icon-button px-2 cursor-pointer"
              onClick={() => onQuerySearch(prompt)}
              disabled={!prompt}
            >
              <span className="material-symbols-outlined md-18 text-secondary">
                search
              </span>
            </Button>
          </div>
          <p className="text-center fs-6 mb-0">
            <small className="text-muted">
              The information provided by AI Insights is based on the provided
              files.
              <br />
              View the anlayzed files and limitations
            </small>
          </p>
        </div>
      </Offcanvas>
    </>
  );
};

export default FilesAiInsights;
