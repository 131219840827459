import React from "react";
import { Helmet } from "react-helmet";
import { truncate } from "lodash";

import { getMimeType } from "features/files/utils/mimeTypeUtil";

import BreadCrumbs from "components/ui/BreadCrumbs";
import PreviewEngine from "../PreviewEngine";
import { stripHTML } from "utils/StringUtils";

const FilePreviewLayout = ({
  fileName,
  description,
  fileSrcURL,
  mimeType,
  fileEXT,
  facility,
  audit,
  question,
  organization,
}) => {
  const breadcrumbs = [];
  const breadcrumbMaxLength = 25;

  if (organization) {
    breadcrumbs.push({
      name: "File Library",
      link: `/files`,
    });
  }

  if (facility) {
    breadcrumbs.push({
      name: truncate(facility?.name, { length: breadcrumbMaxLength }),
      link: `/facilities/${facility?.id || facility?.facilityId}`,
    });
    if (!audit) {
      breadcrumbs.push({
        name: "File Library",
        link: `/facilities/${facility?.id || facility?.facilityId}/files`,
      });
    }
  }
  if (audit) {
    breadcrumbs.push(
      {
        name: truncate(audit?.name, { length: breadcrumbMaxLength }),
        link: `/audits/${audit?.auditId}`,
      },
      {
        name: "File Library",
        link: `/audits/${audit?.auditId}/files`,
      }
    );
  }
  if (question) {
    breadcrumbs.push({
      name: truncate(stripHTML(question?.prompt), {
        length: breadcrumbMaxLength,
      }),
      link: question?.href,
    });
  }

  return (
    <>
      <Helmet>
        <title>
          {"File Library - "} {fileName}
        </title>
      </Helmet>
      <BreadCrumbs
        breadcrumbs={[
          { name: "Dashboard", link: "/" },
          ...breadcrumbs,
          {
            name: `${truncate(fileName, {
              length: breadcrumbMaxLength,
            })}`,
            active: false,
          },
        ]}
      />
      <PreviewEngine
        fileName={fileName}
        description={description}
        fileSrcURL={fileSrcURL}
        mimeType={mimeType || getMimeType(fileEXT)}
        fileEXT={fileEXT}
        backLink={
          breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : null
        }
      />
    </>
  );
};

export default FilePreviewLayout;
