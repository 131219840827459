import React from "react";
import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";
import { sanitizeActionItemObjectKeys } from "features/activityLogs/utils/sanitizeUtils";

const ActionItemActionType = ({ type, attributes, subjectType }) => {
  const isAssigneeChange = subjectType === "action_item_assignee";
  const isFileChange = subjectType === "media";

  const formatAttributes = (attributes) => {
    if (!attributes?.length) return "";
    if (attributes?.length === 1) return attributes[0];

    const allButLast = attributes?.slice(0, -1);
    const last = attributes?.[attributes?.length - 1];

    return `${allButLast.join(", ")} & ${last}`;
  };

  const sanitizeKeys = attributes?.map((att) =>
    sanitizeActionItemObjectKeys(att)
  );

  const attribute = formatAttributes(sanitizeKeys);
  if (type === "created") {
    return (
      <Badge className="bg-primary bg-opacity-10 text-primary">
        {attribute} - {""}
        {isAssigneeChange || isFileChange ? "Added" : "Created"}
      </Badge>
    );
  }
  if (type === "updated") {
    return (
      <Badge className="bg-warning bg-opacity-10 text-warning">
        {attribute} - Updated
      </Badge>
    );
  }
  if (type === "deleted") {
    return (
      <Badge className="bg-danger bg-opacity-10 text-danger">
        {attribute} - {""}
        {isAssigneeChange || isFileChange ? "Removed" : "Deleted"}
      </Badge>
    );
  }
  return "-";
};

ActionItemActionType.propTypes = {
  type: PropTypes.string.isRequired,
  subjectType: PropTypes.string.isRequired,
  attributes: PropTypes.array.isRequired,
};

export default ActionItemActionType;
