import React, { useContext } from "react";

import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
import { Route, Routes, useLocation, useParams } from "react-router-dom";

import BreadCrumbs from "components/ui/BreadCrumbs";
import { OrganizationContext } from "contexts/OrganizationProvider";
import { useFetchPortfolios } from "features/organization/services";
import { createBreadcrumb } from "features/organization/utils/breadcrumpUtil";

import Portfolios from "./Portfolios";
import LeftSidebar from "./LeftSidebar";
import OrganizationOverview from "./OrganizationOverview";
import NoMatch from "components/ui/NoMatch";

function Organization() {
  const { portfolioId } = useParams();
  const { pathname = "" } = useLocation();
  const organization = useContext(OrganizationContext);
  const { error, isLoading, portfolios, setPortfolios } = useFetchPortfolios();

  return (
    <>
      <Helmet>
        <title>{organization?.name || "ERMAssess"}</title>
      </Helmet>
      <BreadCrumbs
        breadcrumbs={createBreadcrumb({
          pathname,
          portfolios,
          portfolioId,
        })}
      />
      <Row>
        <Col xs={12} sm={4} md={12} lg={3} xl={2} className="mb-3">
          <LeftSidebar
            error={error}
            isLoading={isLoading}
            portfolios={portfolios}
            setPortfolios={setPortfolios}
          />
        </Col>
        <Col xs={12} sm={8} md={12} lg={9} xl={10}>
          <Row>
            <Routes>
              <Route index element={<OrganizationOverview />} />
              <Route
                path="portfolios/:portfolioId"
                element={
                  <Portfolios
                    portfolios={portfolios}
                    setPortfolios={setPortfolios}
                  />
                }
              />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Organization;
