import { useMemo, useRef, useState, useEffect, useCallback } from "react";

import {
  Col,
  Card,
  Badge,
  Tooltip,
  Dropdown,
  OverlayTrigger,
} from "react-bootstrap";
import Slider from "react-slick";
import { truncate } from "lodash";

import Loader from "components/ui/Loader";
import {
  badgeClass,
  iconClass,
  iconType,
} from "features/entity/utils/MLClassUtils";
import { settings } from "config/slickSliderConfigs";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import { useFetchFacilityAudits } from "features/assessment";
import MLKeywordSource from "features/assessment/components/Audit/AuditDetails/AuditReport/MLKeywordSource";

const FacilityMLSentimentAnalysis = ({ facilityId, protocol }) => {
  const sliderRef = useRef(null);
  const [show, setShow] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(null);

  const { error, isLoading, audits } = useFetchFacilityAudits({
    fetchByDefault: true,
    facilityId,
    protocol: protocol?.protocolId,
  });
  const [selectedAudit, setSelectedAudit] = useState(
    () => audits[0]?.auditId || ""
  );

  const filteredAudits = useMemo(() => {
    if (!protocol) return audits;
    return audits.filter(
      (audit) => audit.protocol.protocolId === protocol.protocolId
    );
  }, [audits, protocol]);

  useEffect(() => {
    if (filteredAudits.length > 0) {
      setSelectedAudit(filteredAudits[0].auditId);
      sliderRef.current?.slickGoTo(0);
    } else {
      setSelectedAudit("");
    }
  }, [filteredAudits, protocol]);

  const auditOptions = useMemo(
    () =>
      filteredAudits.map((audit) => ({
        label: audit.name,
        value: audit.auditId,
      })),
    [filteredAudits]
  );

  const selectedAuditName =
    auditOptions.find((audit) => audit.value === selectedAudit)?.label ||
    filteredAudits[0]?.name;

  const extractSummaryAnalysis = useMemo(
    () =>
      filteredAudits.flatMap((audit) =>
        (audit.textFieldSummaryAnalysis || []).map((summary) => ({
          auditId: audit.auditId,
          auditName: audit.name,
          label: summary.label || "",
          keywords: summary.keywords || [],
        }))
      ),
    [filteredAudits]
  );

  const filteredSummaries = useMemo(
    () =>
      selectedAudit
        ? extractSummaryAnalysis.filter(
            (summary) => summary.auditId === selectedAudit
          )
        : [],
    [extractSummaryAnalysis, selectedAudit]
  );

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = (keyword) => {
    setSelectedKeyword(keyword);
    setShow(true);
  };

  if (isLoading) return <Loader />;

  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      <Col xs={12} sm={12} md={5} lg={5} xl={5} className="mb-3">
        <Dropdown
          translate="no"
          onSelect={(auditId) => {
            if (selectedAudit !== auditId) {
              setSelectedAudit(auditId);
            }
          }}
        >
          <Dropdown.Toggle
            variant="outline-primary"
            className="border-0 btn-block text-start text-black bg-primary bg-opacity-10"
            size="sm"
          >
            <span
              translate="no"
              className="material-symbols-outlined md-18 me-2"
            >
              assignment
            </span>
            {truncate(selectedAuditName)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {auditOptions.map((audit) => (
              <Dropdown.Item key={audit.value} eventKey={audit.value}>
                {audit.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      {filteredSummaries.length > 0 ? (
        <>
          {error && <ErrorHandler error={error} />}
          {!error && selectedAudit && (
            <>
              <Slider
                {...{
                  ...settings,
                  slidesToShow: 1,
                }}
                arrows={filteredSummaries.length > 1}
                ref={sliderRef}
                className="border-0"
              >
                {filteredSummaries.map((summary, index) => (
                  <div key={index}>
                    <Card.Body>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="summary-tooltip">
                            <p className="text-center w-100">
                              Summary of {summary.label}
                            </p>
                          </Tooltip>
                        }
                      >
                        <p className="text-center w-100 text-wrap">
                          Summary of {summary.label}
                        </p>
                      </OverlayTrigger>
                      <div className="d-flex gap-3 flex-wrap justify-content-center mb-3">
                        {summary.keywords.map((keyword, i) => {
                          return (
                            <OverlayTrigger
                              key={i}
                              placement="top"
                              overlay={
                                <Tooltip id={`keyword-tooltip-${i}`}>
                                  <p className="px-4">Click to show sources</p>
                                  <p className="text-center w-100 text-capitalize ">
                                    <span className="fw-bold">
                                      {keyword.percentage}{" "}
                                    </span>
                                    - {keyword.sentiment}
                                  </p>
                                </Tooltip>
                              }
                            >
                              <Badge
                                className={`cursor-pointer ${badgeClass(
                                  keyword.sentiment,
                                  keyword.percentage
                                )}`}
                                onClick={() => handleShow(keyword)}
                              >
                                <span
                                  className={`material-symbols-outlined ${iconClass(
                                    keyword.sentiment,
                                    keyword.percentage
                                  )} md-16`}
                                >
                                  {iconType(keyword.sentiment)}
                                </span>{" "}
                                <span className="text-capitalize">
                                  {keyword.keyword} ({keyword.count})
                                </span>
                              </Badge>
                            </OverlayTrigger>
                          );
                        })}
                      </div>
                    </Card.Body>
                    <div className="py-2 d-flex justify-content-center">
                      <small className="text-secondary text-center">
                        Generated by AI
                      </small>
                    </div>
                  </div>
                ))}
              </Slider>

              {selectedKeyword && (
                <MLKeywordSource
                  show={show}
                  onHide={handleClose}
                  keyword={selectedKeyword}
                  auditId={selectedAudit}
                />
              )}
            </>
          )}
        </>
      ) : (
        <div className="mt-2">
          <EmptyStateHandler
            title="No Sentiment Analysis"
            description="This assessment does not contain any completed questions."
          />
        </div>
      )}
    </>
  );
};
export default FacilityMLSentimentAnalysis;
