const removeExtraExtensions = (files) => {
  return files.map((file) => {
    const { fileName } = file;
    const lastDotIndex = fileName?.lastIndexOf(".");
    if (lastDotIndex === -1) return file;
    const extension = fileName?.substring(lastDotIndex).toLowerCase();
    let baseFilename = fileName?.substring(0, lastDotIndex);
    while (baseFilename.toLowerCase().endsWith(extension)) {
      baseFilename = baseFilename.substring(
        0,
        baseFilename.length - extension.length
      );
    }
    return { ...file, fileName: baseFilename + extension };
  });
};

const removeDuplicateFiles = (files) => {
  const seen = new Set();
  return files.filter(({ fileName }) => {
    if (!fileName) return false;
    const key = fileName.toLowerCase();
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
};

const formatFileNames = (arr) => {
  if (!Array.isArray(arr) || !arr.length) return "";

  const data = removeExtraExtensions(arr);
  const files = removeDuplicateFiles(data);
  if (files.length === 1) return { filesList: files[0], length: 1 };
  if (files.length === 2)
    return { filesList: `${files[0]} and ${files[1]}`, length: 2 };

  const allButLastTwo = files.slice(0, -2).join(", ");
  const secondToLast = files[files.length - 2];
  const lastOne = files[files.length - 1];

  return {
    filesList: `${allButLastTwo}, ${secondToLast} and ${lastOne}`,
    length: files.length,
  };
};

export { formatFileNames, removeExtraExtensions, removeDuplicateFiles };
