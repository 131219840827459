import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "utils/DeApi";
import { LOADING_MESSAGES } from "../constants";

const useFetchGeneratedExecutiveSummary = ({ messageIndexRef }) => {
  const [generatedSummary, setGeneratedSummary] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Initializing ML engine"
  );
  const { auditId } = useParams();
  const subscribedPromises = useRef([]);

  const fetchExecutiveSummary = () => {
    setIsLoading(true);
    setError(null);
    setLoadingMessage(LOADING_MESSAGES[0]);
    messageIndexRef.current = 0;

    const promise = get(`ml/audits/${auditId}/summary/generate`).promise;

    promise
      .then(({ data }) => {
        setGeneratedSummary(data.summary);
      })
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));

    subscribedPromises.current.push(promise);
  };

  useEffect(() => {
    fetchExecutiveSummary();
    const promises = subscribedPromises.current;

    return () => {
      promises.forEach((promise) => promise?.cancel?.());
    };
  }, []);

  return {
    generatedSummary,
    error,
    isLoading,
    loadingMessage,
    setLoadingMessage,
    fetchExecutiveSummary,
  };
};

export default useFetchGeneratedExecutiveSummary;
