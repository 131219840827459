import { useContext } from "react";

import { Helmet } from "react-helmet";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import BreadCrumbs from "components/ui/BreadCrumbs";
import { createCrumpTitle } from "features/account/utils";
import { OrganizationContext } from "contexts/OrganizationProvider";

import Members from "./Members";
import EntityTags from "./EntityTags";
import AssessmentTags from "./AssessmentTags";
import OrganizationRedirect from "../common/OrganizationRedirect";
import { OrganizationCredentials } from "./OrganizationCredentials";

import "./AccountManagement.scss";
import { ActionItemTags } from "features/actionItems";
import NoMatch from "components/ui/NoMatch";

const AccountManagement = () => {
  const { pathname = "" } = useLocation() || {};
  const organization = useContext(OrganizationContext);

  const URLSnippet = `/organizations/${organization?.id}`;

  const navItems = [
    {
      to: "members",
      label: "Members",
      isActive: pathname === `${URLSnippet}/members`,
    },
    {
      to: "entity-tags",
      label: "Entity tags",
      isActive: pathname === `${URLSnippet}/entity-tags`,
    },
    {
      to: "action-item-tags",
      label: "Action Item tags",
      isActive: pathname === `${URLSnippet}/action-item-tags`,
    },
    {
      to: "assessment-tags",
      label: "Assessment Tags",
      isActive: pathname === `${URLSnippet}/assessment-tags`,
    },
    {
      to: "organization-credentials",
      label: "Organization Credentials",
      isActive: pathname === `${URLSnippet}/organization-credentials`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Account Management : {organization?.name ?? "Organization"}
        </title>
      </Helmet>
      <BreadCrumbs
        breadcrumbs={[
          { name: "Dashboard", link: "/" },
          { name: "Account Management", active: false },
          {
            name: createCrumpTitle({
              pathname: pathname,
              organizationId: organization?.id,
            }),
            active: false,
          },
        ]}
      />
      <h1>Account Management</h1>
      <p>Account management and member permissioning dashboard.</p>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="mb-4">
          <Card>
            <Card.Body>
              <Nav variant="tabs" className="mb-3">
                {navItems.map(({ to, label, isActive }) => (
                  <Nav.Item key={to}>
                    <Nav.Link to={to} as={Link} active={isActive}>
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Routes>
                <Route path="members" element={<Members />} />
                <Route
                  path="organization-credentials"
                  element={<OrganizationCredentials />}
                />
                <Route path="entity-tags" element={<EntityTags />} />
                <Route path="assessment-tags" element={<AssessmentTags />} />
                <Route path="action-item-tags" element={<ActionItemTags />} />
                <Route
                  index
                  path="redirect"
                  element={<OrganizationRedirect />}
                />
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AccountManagement;
