import React, { useCallback, useMemo, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Text,
  Range,
} from "slate";
import { withHistory } from "slate-history";
import { jsx } from "slate-hyperscript";
import escapeHtml from "escape-html";

import { Button, Icon, Toolbar } from "./components";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
const DOUBLE_ENTER_DETECTION_SECONDS = 3000;

const cleanUpListItems = (parsedDocument) => {
  const listItems = parsedDocument.querySelectorAll("li");

  listItems.forEach((li) => {
    const pTag = li.querySelector("p");

    if (pTag) {
      const span = document.createElement("span");

      while (pTag.firstChild) {
        span.appendChild(pTag.firstChild);
      }

      li.replaceChild(span, pTag);
    }
  });
};

const SlateEditor = ({
  name,
  setFieldValue,
  oldValue,
  placeholder,
  minHeight,
  customClassNames = [],
  module = "",
}) => {
  const defaultClassNames = [
    "form-control ",
    " border-0 ",
    " border-top ",
    "rounded-0",
  ];
  const combineClassNames = [...defaultClassNames, ...customClassNames].join(
    " "
  );
  const withHtml = useCallback((editor) => {
    const { insertData, isInline, isVoid } = editor;

    editor.isInline = (element) => {
      return element.type === "link" ? true : isInline(element);
    };

    editor.isVoid = (element) => {
      return element.type === "image" ? true : isVoid(element);
    };

    editor.insertData = (data) => {
      const html = data.getData("text/html");

      if (html) {
        const parsed = new DOMParser().parseFromString(html, "text/html");

        cleanUpListItems(parsed);
        const fragment = deserialize(parsed.body);

        Transforms.insertFragment(editor, fragment);
        return;
      }

      insertData(data);
    };

    return editor;
  }, []);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withHtml(withReact(withHistory(createEditor()))),
    []
  );

  const deserialize = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
      return jsx("text", markAttributes, el.textContent);
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
      return null;
    }

    const nodeAttributes = { ...markAttributes };

    // define attributes for text nodes
    // eslint-disable-next-line default-case
    switch (el.nodeName) {
      case "STRONG":
        nodeAttributes.bold = true;
        nodeAttributes.align = "right";
        break;
      case "ITALIC":
        nodeAttributes.italic = true;
        break;
      case "UNDERLINE":
        nodeAttributes.underline = true;
        break;
    }

    const children = Array.from(el.childNodes)
      .map((node) => deserialize(node, nodeAttributes))
      .flat();

    if (children.length === 0) {
      children.push(jsx("text", nodeAttributes, ""));
    }

    let style = {
      align: el.style?.textAlign,
    };

    switch (el.nodeName) {
      case "BODY":
        return jsx("fragment", {}, children);
      case "BR":
        return "\n";
      case "BLOCKQUOTE":
        return jsx("element", { type: "quote", ...style }, children);
      case "P":
        return jsx("element", { type: "paragraph", ...style }, children);
      case "UL":
        return jsx("element", { type: "bulleted-list", ...style }, children);
      case "OL":
        return jsx("element", { type: "numbered-list", ...style }, children);
      case "LI":
        return jsx("element", { type: "list-item", ...style }, children);
      case "H1":
        return jsx("element", { type: "heading-one", ...style }, children);
      case "H2":
        return jsx("element", { type: "heading-two", ...style }, children);
      case "H3":
        return jsx("element", { type: "heading-three", ...style }, children);
      case "H4":
        return jsx("element", { type: "heading-four", ...style }, children);
      case "H5":
        return jsx("element", { type: "heading-five", ...style }, children);
      case "H6":
        return jsx("element", { type: "heading-six", ...style }, children);
      case "A":
        return jsx(
          "element",
          { type: "link", url: el.getAttribute("href") },
          children
        );
      default:
        return children;
    }
  };

  const initialValue = useMemo(() => {
    const tmpvalue = oldValue;
    if (tmpvalue) {
      const doc = new DOMParser().parseFromString(tmpvalue, "text/html");
      const body = doc.body;
      return deserialize(body);
    }
    return [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];
  }, [oldValue]);

  const [value, setValue] = useState(initialValue);
  const [lastEnterTime, setLastEnterTime] = useState(0);

  const serialize = (node) => {
    if (Text.isText(node)) {
      let string = escapeHtml(node.text);
      if (node.bold) {
        string = `<strong>${string}</strong>`;
      }
      if (node.italic) {
        string = `<em>${string}</em>`;
      }
      if (node.underline) {
        string = `<u>${string}</u>`;
      }
      return string;
    }

    const children = node.children.map((n) => serialize(n)).join("");

    let style = "";

    if (node.align) {
      style = `"text-align: ${node.align};"`;
    }

    switch (node.type) {
      case "quote":
        return `<blockquote><p${
          style.length > 0 ? ` style= ${style}` : ``
        }>${children}</p></blockquote>`;
      case "paragraph":
        return `<p${
          style.length > 0 ? ` style= ${style}` : ``
        }>${children}</p>`;
      case "bulleted-list":
        return `<ul${
          style.length > 0 ? ` style= ${style}` : ``
        }>${children}</ul>`;
      case "numbered-list":
        return `<ol${
          style.length > 0 ? ` style= ${style}` : ``
        }>${children}</ol>`;
      case "list-item":
        return `<li${
          style.length > 0 ? ` style= ${style}` : ``
        }>${children}</li>`;
      case "link":
        return `<a href="${escapeHtml(node.url)}"${
          style.length > 0 ? ` style= ${style}` : ``
        }>${children}</a>`;
      default:
        return children;
    }
  };

  const handlePaste = async (event, _, next) => {
    // if copied content is not HTML, paste it as plain text otherwise carry the logic below
    event.preventDefault();
    const clipboardHTML = await navigator.clipboard.readText();

    //check if the pasted content is html or plain text using regex
    const htmlRegex =
      /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i;
    if (!htmlRegex.test(clipboardHTML)) return editor.insertText(clipboardHTML);

    const document = new DOMParser().parseFromString(
      clipboardHTML,
      "text/html"
    );

    cleanUpListItems(document);

    const fragment = deserialize(document.body);

    const removeNewLine = (nodes) => {
      if (!Array.isArray(nodes)) return [];

      return nodes
        .map((node) => {
          if (node.children) {
            node.children = removeNewLine(node.children);
          }
          return node;
        })
        .filter((node) => !(node.text && node.text.includes("\n")));
    };

    const fragmentRemovedNewLine = removeNewLine(fragment);

    const removeBrokenFragment = (nodes) => {
      if (!Array.isArray(nodes)) return [];

      return nodes
        .map((node) => {
          if (node.children) {
            node.children = removeBrokenFragment(node.children);
          }
          return node;
        })
        .filter(
          (node) => node.hasOwnProperty("text") || node.children.length > 0
        );
    };

    const removeEmptyText = removeBrokenFragment(fragmentRemovedNewLine);

    editor.insertFragment(removeNewLine(removeEmptyText));
  };

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
        setFieldValue(name, serialize(editor));
      }}
    >
      <Toolbar className="my-2">
        <div />
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />
        <div className="opacity-25" style={{ width: 6 }}>
          |
        </div>
        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
        <div className="opacity-25" style={{ width: 6 }}>
          |
        </div>
        <BlockButton format="left" icon="format_align_left" />
        <BlockButton format="center" icon="format_align_center" />
        <BlockButton format="right" icon="format_align_right" />
        <BlockButton format="justify" icon="format_align_justify" />
      </Toolbar>
      <Editable
        className={combineClassNames}
        style={{ minHeight: minHeight || 100 }}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={placeholder}
        spellCheck
        translate="no"
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }

          const key = event.key || event.which || event.keyCode;
          if (key === "Enter" || key === 13) {
            const currentTime = new Date().getTime();
            const { selection } = editor;

            if (selection && Range.isCollapsed(selection)) {
              const [match] = Editor.nodes(editor, {
                match: (n) => LIST_TYPES.includes(n.type),
              });

              if (match) {
                if (
                  currentTime - lastEnterTime <
                  DOUBLE_ENTER_DETECTION_SECONDS
                ) {
                  event.preventDefault();

                  // Break out of the list item by converting it to a paragraph
                  Transforms.unwrapNodes(editor, {
                    match: (n) => LIST_TYPES.includes(n.type),
                    split: true,
                  });

                  // Insert a new paragraph node
                  Transforms.setNodes(editor, { type: "paragraph" });

                  setLastEnterTime(0); // Reset the lastEnterTime to avoid immediate subsequent triggers
                  return;
                } else {
                  setLastEnterTime(currentTime);
                }
              }
              if (module !== "reports") {
                event.preventDefault();
                Transforms.insertText(editor, "\n");
              }
            }
          } else {
            setLastEnterTime(0);
          }
        }}
        onPaste={handlePaste}
      />
    </Slate>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );

    case "heading-three":
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "heading-four":
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case "heading-five":
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case "heading-six":
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "link":
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      );

    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon className="text-center">{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon className="text-center">{icon}</Icon>
    </Button>
  );
};

export default SlateEditor;
