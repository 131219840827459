import React, { useState } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";

import RequiredAsterisk from "components/ui/RequiredAsterisk";
import ErrorHandler from "components/ui/ErrorHandler";
import { useUploadActionItemFiles } from "features/actionItems/services";
import { actionItemFileValidationSchema } from "features/actionItems/schemas";
const ERROR_MESSAGE_UNAUTHORIZED =
  "You are not authorized to access that resource!";
const EvidenceFileUpload = ({ actionItemId, onEvidenceFileUploaded }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { uploadFile, isLoading, error } = useUploadActionItemFiles({
    actionItemId,
    onEvidenceFileUploaded: (val) => {
      onEvidenceFileUploaded(val);
      handleClose();
    },
  });

  const onFileUpload = (setVal) => (e) => {
    const _file = e.currentTarget.files[0];
    setVal("file", _file);
    if (typeof _file === "object") setVal("name", _file.name);
  };

  return (
    <>
      <Button
        className="mt-2 py-0"
        variant="outline-primary"
        size="sm"
        onClick={handleShow}
        disabled={!actionItemId}
      >
        <span translate="no" className="material-symbols-outlined md-18">
          file_upload
        </span>{" "}
        Upload
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Evidence File</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={actionItemFileValidationSchema}
          onSubmit={(values) => uploadFile(values)}
          initialValues={{
            file: "",
          }}
        >
          {({ handleSubmit, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="file" className="mb-3">
                  <Form.Label>
                    Upload File <RequiredAsterisk />
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={onFileUpload(setFieldValue)}
                    onBlur={onFileUpload(setFieldValue)}
                    isInvalid={!(values.file && !errors.file) && touched.file}
                    isValid={values.file && !errors.file}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.file && touched.file ? (
                      <small>{errors.file}</small>
                    ) : null}
                  </Form.Control.Feedback>
                </Form.Group>
                {error &&
                  error?.status === 403 &&
                  ERROR_MESSAGE_UNAUTHORIZED ===
                    error?.data?.error?.message && (
                    <Alert variant="info" className={`my-3 d-flex flex-row `}>
                      <div className="me-3">
                        <span
                          translate="no"
                          className="material-symbols-outlined md-18"
                        >
                          lock
                        </span>
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-1">
                          <small>Request For Access</small>
                        </h5>
                        <p>
                          Only the action item creator and assignees can upload
                          files
                        </p>
                      </div>
                    </Alert>
                  )}
                {error &&
                  error?.data?.error?.message !==
                    ERROR_MESSAGE_UNAUTHORIZED && (
                    <ErrorHandler error={error} />
                  )}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="light" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" size="sm" disabled={!values?.file}>
                  {isLoading && (
                    <Spinner
                      className="me-2"
                      animation="border"
                      size="sm"
                      variant="light"
                    />
                  )}{" "}
                  Upload evidence file
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default EvidenceFileUpload;

EvidenceFileUpload.propTypes = {
  actionItemId: PropTypes.string.isRequired,
  onEvidenceFileUploaded: PropTypes.func.isRequired,
};
